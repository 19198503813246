
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"

export default {
  middleware: (context) => {
    if (!context.$permissions.user.can.access.projectSettings()) {
      context.redirect("/v2/projects")
    }
  },
  data() {
    return {
      contentHeight: 0,
    }
  },
  computed: {
    ...mapStores(useProjectStore),
    project() {
      return this.projectStore.selectedProject
    },
    selectedProjectExid() {
      return this.projectStore.selectedProjectExid
    },
    allItems() {
      return [
        {
          id: "project-overview",
          title: "Overview",
          link: `/v2/projects/${this.selectedProjectExid}/settings`,
        },
        {
          id: "camera-sharing",
          title: "Members",
          link: `/v2/projects/${this.selectedProjectExid}/settings/members`,
        },
        {
          id: "camera-overview",
          title: "Camera Settings",
          link: `/v2/projects/${this.selectedProjectExid}/settings/camera-settings`,
        },
      ]
    },
    contentStyles() {
      return {
        height: `${this.contentHeight}px`,
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.onContentDimensionsChange()
    })
  },
  methods: {
    onContentDimensionsChange() {
      const top = this.$refs.settingsContent.getBoundingClientRect().top
      this.contentHeight = window.innerHeight - top
    },
  },
}
